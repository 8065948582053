!(function (e, t) {
    'object' == typeof exports && 'undefined' != typeof module
        ? t(exports, require('react'))
        : 'function' == typeof define && define.amd
        ? define(['exports', 'react'], t)
        : t(((e = e || self)['react-dismissible'] = {}), e.React)
})(this, function (e, p) {
    'use strict'
    var l = function () {
        return (l =
            Object.assign ||
            function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++)
                    for (var r in (t = arguments[n])) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r])
                return e
            }).apply(this, arguments)
    }
    ;(e.Dismissible = function (e) {
        var o = e.onDismiss,
            t = e.escape,
            r = void 0 !== t && t,
            n = e.click,
            i = void 0 !== n && n,
            c = e.disabled,
            s = void 0 !== c && c,
            a = e.document,
            f = (function (e, t) {
                var n = {}
                for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o])
                if (null != e && 'function' == typeof Object.getOwnPropertySymbols) {
                    var r = 0
                    for (o = Object.getOwnPropertySymbols(e); r < o.length; r++)
                        t.indexOf(o[r]) < 0 &&
                            Object.prototype.propertyIsEnumerable.call(e, o[r]) &&
                            (n[o[r]] = e[o[r]])
                }
                return n
            })(e, ['onDismiss', 'escape', 'click', 'disabled', 'document']),
            u = p.useRef(),
            d = a ? [document, a] : [document]
        return (
            p.useEffect(
                function () {
                    u.current.addEventListener('mousedown', (e) => {
                        window.clickStartedInDismissible = u.current.contains(e.target);
                    });

                    window.addEventListener('mouseup', (e) => {
                        window.preventDismissibleClose = u.current && !u.current.contains(e.target) && window.clickStartedInDismissible
                    });

                    function t(e) {
                        var dismissFn = function(e){
                            var haveSelection = window.getSelection().toString().length > 0
                            if(window.preventDismissibleClose && haveSelection){
                                window.clickStartedInDismissible = false
                                window.preventDismissibleClose = false
                                return;
                            } else {
                                o(e)
                            }
                        }

                        s ||
                            u.current.contains(e.target) ||
                            ((function (e) {
                                e.stopPropagation(), e.stopImmediatePropagation(), e.preventDefault()
                            })(e),
                            dismissFn(e))
                    }
                    function n(e) {
                        s || (27 === e.keyCode && (e.stopPropagation(), o(e)))
                    }
                    return (
                        i &&
                            d.forEach(function (e) {
                                return e.body.addEventListener('click', t)
                            }),
                        r &&
                            d.forEach(function (e) {
                                return e.addEventListener('keydown', n)
                            }),
                        function () {
                            d.forEach(function (e) {
                                e.body.removeEventListener('click', t), e.removeEventListener('keydown', n)
                            })
                        }
                    )
                },
                [i, document, a, r, s, o]
            ),
            p.createElement('div', l({ ref: u }, f))
        )
    }),
        Object.defineProperty(e, '__esModule', { value: !0 })
})
